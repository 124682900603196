import { SimpleItemListExtensions as Base } from 'core/extensions/SimpleItemListExtensions'
import { DecodedItem, EnumerationValue, EnumerationValues, SimpleItemList } from 'stylewhere/api'
import { CreateParcelGatePackagingCodeField } from 'stylewhere/components'
import {
  AppStore,
  FormSchema,
  FormSchemaData,
  getDataFromSchema,
  OperationConfig,
  SimpleItemListOperationConfig,
} from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { showToastError, showToast } from 'stylewhere/utils'

export class SimpleItemListExtensions extends Base {
  static formSchema(operation: SimpleItemListOperationConfig, onModal = false) {
    const formSchema = super.formSchema(operation)
    if (operation.code === 'create-parcel-gate') {
      const packagingCodeField = formSchema.find(({ name }) => name === 'attributes.packagingCode')
      if (packagingCodeField === undefined) {
        throw new Error('Missing field attributes.packagingCode in schema for operation create-parcel-gate')
      }
      packagingCodeField.customRender = (props) => <CreateParcelGatePackagingCodeField onModal={onModal} {...props} />
    }
    return formSchema
  }

  static async processItem(
    operation: SimpleItemListOperationConfig,
    formData: FormSchemaData<{ attributes?: { enableAutoPrint?: boolean } }>,
    item: DecodedItem
  ): Promise<DecodedItem | null> {
    await super.processItem(operation, formData, item)
    if (formData.attributes?.enableAutoPrint || operation.confirmOnItemRead) {
      const schema = SimpleItemListExtensions.formSchema(operation)
      const transferData = getDataFromSchema(formData, schema)
      transferData.attributes.singleRead = true
      try {
        SimpleItemList.save({
          ...transferData,
          operationId: operation.id,
          operationPlaceId: AppStore.defaultWorkstation?.placeId ?? '',
          itemIds: [item.item?.id],
        })
          .then(() => {})
          .catch((err) => {
            showToast({
              title: __(T.error.error),
              description: 'Error in single read confirmation',
              status: 'error',
            })
          })
      } catch (error) {
        showToast({
          title: __(T.error.error),
          description: 'Error in single read confirmation',
          status: 'error',
        })
      }
    }
    return item
  }

  static async formDataIsValid(formData: any, operation: OperationConfig, schema: FormSchema): Promise<boolean> {
    if (operation.code === 'create-parcel-gate') {
      if (!formData?.attributes?.packagingCode) return super.formDataIsValid(formData, operation, schema)
      const result = await EnumerationValues.search<EnumerationValue<any>>({
        enumerationTypeCode: 'PACKAGING',
        equalCodes: formData?.attributes?.packagingCode,
      })
      if (result.content.length !== 1) {
        //`${__(T.misc.pack_code)} ${formData?.attributes?.packagingCode} ${__(T.error.not_found).toLowerCase()}`,
        showToastError('', __(T.custom.wrong_packaging_code))
        return false
      }
    }
    if (operation.code === 'warehouseusa-relabeling') {
      if (!formData?.attributes?.originLocation) {
        showToastError( __(T.custom.origin_location_mandatory_field_error), __(T.error.error))
        return false
      }
      if (!formData?.attributes?.transactionDate) {
        showToastError( __(T.custom.transactionDate_mandatory_field_error), __(T.error.error))
        return false
      }
      if (formData?.attributes?.printPriceLabel === true && !formData?.attributes?.customerCode) {
        showToastError( __(T.custom.customerCode_mandatory_field_error), __(T.error.error))
        return false
      }
      if ((formData?.attributes?.typeBlankEnabled && formData?.attributes?.tipology)) {
        showToastError(__(T.custom.tipology_field_error), __(T.error.fields_required))
        return false
      }
      if (!formData?.attributes?.model && !formData?.attributes?.color && (!formData?.attributes?.typeBlankEnabled && !formData?.attributes?.tipology) && !formData?.attributes?.season) {
        showToastError(__(T.custom.warehouseUsa_relabeling_mandatory_fields_error), __(T.error.fields_required))
        return false
      }
    }
    return super.formDataIsValid(formData, operation, schema)
  }

  static validateForm(formData: any, operation: OperationConfig){
    const errors :string[] = []
    if (operation.code === 'warehouseusa-relabeling') {
      if (!formData?.attributes?.originLocation) {
        errors.push(__(T.custom.origin_location_mandatory_field_error))
      }
      if (!formData?.attributes?.transactionDate) {
        errors.push( __(T.custom.transactionDate_mandatory_field_error))
      }
      if (formData?.attributes?.printPriceLabel === true && !formData?.attributes?.customerCode) {
        errors.push( __(T.custom.customerCode_mandatory_field_error))
      }
      if ((formData?.attributes?.typeBlankEnabled && formData?.attributes?.tipology)) {
        errors.push(__(T.custom.tipology_field_error))
      }
      if (!formData?.attributes?.model && !formData?.attributes?.color && (!formData?.attributes?.typeBlankEnabled && !formData?.attributes?.tipology) && !formData?.attributes?.season) {
        errors.push(__(T.custom.warehouseUsa_relabeling_mandatory_fields_error))
      }
    }
    return errors
  }

  static async beforeConfirm(operation: OperationConfig, data: any, objects): Promise<boolean> {
    if (operation.code === 'create-parcel-gate') {
      const packagingCode = data.attributes.packagingCode
      if (packagingCode && packagingCode !== null && packagingCode !== '') {
        const result = await EnumerationValues.search<EnumerationValue<any>>({
          enumerationTypeCode: 'PACKAGING',
          equalCodes: packagingCode,
        })
        if (result && result.content && result.content.length > 0) {
          data.attributes.packagingCode = result.content[0].code
        } else {
          showToastError('', __(T.custom.wrong_packaging_code))
          throw new Error()
        }
      }
    }
    return super.beforeConfirm(operation, data, objects)
  }
}
