import {
  ConfigurationDetail,
  EncodingCounters,
  Encodings,
  EncodingValidationRequest,
  EncodingValidationResponse,
  IdentifierType,
  Products,
  TmrTag,
} from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { AppStore, FormSchema, FormSchemaData, OperationConfig, jsonToFormSchema, Router } from 'stylewhere/shared'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { Extensions } from './Extensions'
import { getInitialType, getEncodingBlockedErrors } from 'stylewhere/shared/utils'
import { get as _get } from 'lodash'
import { id } from 'date-fns/locale'

export class EncodingExtensions extends Extensions {
  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    // Possono essere una lista di items, di parcels o altro a seconda del tipo di operazione
    objects: O
  ): Promise<boolean> {
    return true
  }

  static formSchema(operation: EncodingOperationConfig, additionalData?: any, filterKey?: string[]): FormSchema {
    if (!operation) return []
    const initialType = getInitialType(operation)
    if (initialType === 'formSchema' && operation.formSchema && Object.keys(operation.formSchema).length > 0)
      return jsonToFormSchema(operation.formSchema as any)

    if (operation.templatePath && operation.templatePath === 'encodingOutboundSap') {
      return []
    }

    if (initialType === 'product') {
      return [
        {
          type: 'text',
          name: 'product.code',
          label: __(T.misc.upc),
          required: true,
          autoFocus: true,
        },
      ]
    }

    if (initialType === 'wam') {
      return [
        {
          type: 'text',
          name: 'wam',
          label: __(T.custom.WAM),
          required: true,
          autoFocus: true,
        },
      ]
    }

    if (initialType === 'order') return this.getOrderFormSchema(operation, additionalData, true)

    if (initialType === 'orderRow') return this.getOrderFormSchema(operation, additionalData, false)

    return []
  }

  static getOrderFormSchema(
    operation: EncodingOperationConfig,
    additionalData: any = {},
    showProductionOrder: boolean
  ): FormSchema {
    const mode =
      operation.options && operation.options.productionOrderRowSelectionMode
        ? operation.options.productionOrderRowSelectionMode
        : 'byOrderAndRowCode'

    const formSchema: FormSchema = []
    if (showProductionOrder)
      formSchema.push({
        type: 'text',
        name: 'productionOrder',
        label: __(T.misc.production_order),
        required: !additionalData.productionOrder,
        autoFocus: !additionalData.productionOrder,
        hide: !!additionalData.productionOrder,
      })

    formSchema.push({
      type: 'text',
      name: 'productionOrderRow',
      label: mode === 'byOrderAndRowCode' ? __(T.misc.production_order_row) : __(T.misc.production_order_sku),
      required: !!additionalData.productionOrder || !showProductionOrder,
      autoFocus: !!additionalData.productionOrder || !showProductionOrder,
      hide: !additionalData.productionOrder && showProductionOrder,
    })

    return formSchema
  }

  static async getProductId(productCode, operationId) {
    const encodingProductsResult = await Encodings.findProduct({ code: productCode, operationId })
    if (encodingProductsResult.ok) {
      return encodingProductsResult.data?.id
    } else if (encodingProductsResult.status !== 400) {
      const products = await Products.searchCodes([productCode])
      if (products.content?.[0]?.id) {
        return products.content[0].id
      }
    }

    throw new Error(__(T.error.no_item_configuration))
  }

  static isExpectOnlyMandatoryIdentifiers = (operation: EncodingOperationConfig) => {
    return operation.options && operation.options.expectOnlyMandatoryIdentifiers
      ? operation.options.expectOnlyMandatoryIdentifiers
      : false
  }

  static getDataDogFormSchemaLabel = (
    operation: EncodingOperationConfig,
    data: FormSchemaData,
    initialType: string
  ) => {
    let label = ''
    if (initialType === 'identifier') {
      label = _get(data, 'identifier') ?? ''
    } else if (initialType === 'formSchema') {
      const schema = EncodingExtensions.formSchema(operation, data)
      const find = schema.find((el) => el.isProductCode)
      if (find) {
        label = _get(data, find.name) ?? ''
      }
    } else if (initialType === 'product') {
      label = data.product.code
    } else if (initialType === 'order' || initialType === 'orderRow') {
      label = data.productionOrderRow.code
    } else if (initialType === 'wam') {
      label = data.wam
    }
    return label
  }

  static async getItemConfiguration(
    operation: EncodingOperationConfig,
    data: FormSchemaData,
    operationAttributes?: any
  ) {
    try {
      const initialType = getInitialType(operation)
      const encodingValidation: EncodingValidationRequest = {
        identifiers: [],
        operationId: operation.id,
        placeId: AppStore.defaultWorkstation!.placeId,
        workstationId: AppStore.defaultWorkstation!.id,
        operationAttributes: operationAttributes || {},
      }
      if (initialType === 'formSchema') {
        const schema = EncodingExtensions.formSchema(operation, data)
        const find = schema.find((el) => el.isProductCode)
        if (find) {
          encodingValidation.startEntityId = await this.getProductId(_get(data, find.name), operation.id)
        } else throw new Error(__(T.error.no_item_configuration))
      } else if (initialType === 'product') {
        encodingValidation.startEntityId = await this.getProductId(data.product.code, operation.id)
      } else if (initialType === 'order' || initialType === 'orderRow') {
        encodingValidation.startEntityId = data.productionOrderRow.id
      } else if (initialType === 'wam') {
        encodingValidation.startCode = data.wam
      }
      const res = await Encodings.validate(encodingValidation)

      if (res) {
        let configurationDetails
        if (res.configurationDetails) configurationDetails = res.configurationDetails
        else if (res.configuration && res.configuration.details) configurationDetails = res.configuration.details
        if (!configurationDetails) configurationDetails = []

        if (configurationDetails.length === 0) {
          const missingOptionError = res.errors.find((e) => e.errorCode === 'Missing Operation Option')
          if (missingOptionError) {
            throw new Error(__(T.error.missing_operation_option, { error: missingOptionError.ref }))
          }
          throw new Error(__(T.error.no_item_configuration))
        }

        const blockedError = res.errors.find((e) => getEncodingBlockedErrors().includes(e.errorCode))
        if (blockedError) {
          throw new Error(__(T.error[blockedError.errorCode]))
        }

        let counters: EncodingCounters | undefined
        if ((initialType === 'order' || initialType === 'orderRow') && !!encodingValidation.startEntityId) {
          counters = (await Encodings.getProductionOrderRowCounter(encodingValidation.startEntityId)).data
        }

        const expectOnlyMandatoryIdentifiers = this.isExpectOnlyMandatoryIdentifiers(operation)
        configurationDetails.forEach((configDetail) => {
          if (!expectOnlyMandatoryIdentifiers || (expectOnlyMandatoryIdentifiers && !configDetail.optional)) {
            encodingValidation.identifiers.push({ ...configDetail })
          }
        })
        return { encodingValidationResponse: res, encodingValidation, counters }
      } else {
        throw new Error(__(T.error.no_item_configuration))
      }
    } catch (error) {
      throw new Error((error as any)?.message ?? __(T.error.no_item_configuration))
    }
  }

  static async set_identifiers_status(encodingValidation: EncodingValidationRequest, result?: any) {
    encodingValidation.identifiers
      .filter((idf) => !!idf.code)
      .forEach((idf) => {
        idf._status = 'CONFIRMED'
        idf._error = undefined
      })
    if (result && result.item && result.item.identifiers) {
      encodingValidation.identifiers.filter(f => f.identifierType === 'UHF_TAG').forEach((idf) => {
        const find = result.item.identifiers.find(
          (idt) => idt.code === idf.code && idt.role === idf.role && idt.type === idf.identifierType
        )
        if (find && !!find.group) {
          const group = result.item.identifiers.find(
            (idt) => idt.group === find.group && idt.code !== idf.code && idt.type !== find.type
          )
          if (group) {
            //check if already exist same identifier read
            const exist = encodingValidation.identifiers.filter(
              (idft) =>
                idft.identifierType === group.type &&
                idft.code !== group.code &&
                idft.role === group.role &&
                idft._read &&
                idft._status !== 'ERROR'
            )
            if (exist.length > 0) {
              encodingValidation.identifiers.push({
                identifierType: group.type,
                role: exist[0].role ?? '',
                code: exist[0].code ?? '',
                _read: true,
                _status: 'ERROR',
                _error: 'Extra tag',
              })
            }
            encodingValidation.identifiers
              .filter((idft) => idft.identifierType === group.type && idft._status !== 'ERROR')
              .forEach((idft) => {
                idft._status = 'CONFIRMED'
                idft._error = undefined
                idft.code = group.code
              })
          }
        }
      })
    }

    if (
      result &&
      result.item &&
      result.item.attributes &&
      result.item.attributes.ignoredTags &&
      result.item.attributes.ignoredTags !== ''
    ) {
      const ignoredTags = result.item.attributes.ignoredTags.split(',')
      if (ignoredTags.length > 0) {
        ignoredTags.forEach((tag) => {
          const index = encodingValidation.identifiers.findIndex((idf) => idf.code === tag.trim())
          if (index >= 0) {
            encodingValidation.identifiers[index]._status = 'IGNORED'
          }
        })
      }
    }
    if (result && result.errors) {
      let errorStr
      result.errors.forEach((error) => {
        if (error.errorCode === 'Tag Mismatch') {
          encodingValidation.identifiers.forEach((idf) => {
            idf._status = 'ERROR'
            idf._error = T.identifier_error[error.errorCode] ? __(T.identifier_error[error.errorCode]) : error.errorCode
          })
        } else {
          // search by code
          const index = encodingValidation.identifiers.findIndex((idf) => idf.code === error.ref)
          /*if(index === -1) { // search by role when tag not read
            index = encodingValidation.identifiers.findIndex((idf) => idf.role === error.ref)
          }*/
          if (index >= 0) {
            errorStr = T.identifier_error[error.errorCode] ? __(T.identifier_error[error.errorCode]) : error.errorCode
            encodingValidation.identifiers[index]._status = 'ERROR'
            if (!encodingValidation.identifiers[index]._error) {
              encodingValidation.identifiers[index]._error = errorStr
            } else {
              encodingValidation.identifiers[index]._error += ', ' + errorStr
            }
          }
        }
      })
    }
    return encodingValidation
  }

  static async validate(encodingValidation: EncodingValidationRequest, checkConfiguration = true, desiredCode = false) {
    try {
      const identifiers: ConfigurationDetail[] = encodingValidation.identifiers
        .map((idf) => ({
          identifierType: idf.identifierType,
          code: idf.code,
          tid: idf.tid || '',
          desiredCode: desiredCode ? idf.desiredCode : undefined,
        }))
        .filter((idf) => !!idf.code)
      const res = await Encodings.validate({ ...encodingValidation, identifiers })

      if (res) {
        await this.set_identifiers_status(encodingValidation, res)
        if (checkConfiguration) {
          let configurationDetails
          if (res?.configurationDetails) configurationDetails = res.configurationDetails
          else if (res.configuration && res.configuration.details) configurationDetails = res.configuration.details
          if ((configurationDetails ?? []).length === 0) {
            this.throwNoConfigurationError()
          }
        }
        return { encodingValidationResponse: res, encodingValidation }
      } else {
        throw new Error(__(T.error.no_item_configuration))
      }
    } catch (error) {
      throw new Error((error as any)?.message ?? __(T.error.no_item_configuration))
    }
  }

  static throwNoConfigurationError(): never {
    throw new Error(__(T.error.no_item_configuration))
  }

  static getMixPanelData(
    operation: EncodingOperationConfig,
    encodingValidation?: EncodingValidationRequest,
    encodingValidationResponse?,
    seconds?,
    force?
  ) {
    const identifiers: any[] = encodingValidation ? this.getIdentifiers(encodingValidation!) : []

    const antennas: any = []
    if (
      AppStore.defaultWorkstation &&
      AppStore.defaultWorkstation.antennas &&
      AppStore.defaultWorkstation.antennas.length > 0
    ) {
      AppStore.defaultWorkstation.antennas.map((antenna) => {
        antennas.push({
          code: antenna.code,
          txPower: antenna.txPower,
          rxSensitivity: antenna.rxSensitivity,
          reader: {
            code: antenna.reader.code,
            deviceType: antenna.reader.deviceType,
            settings: antenna.reader.settings,
          },
        })
      })
    }
    const mixpanelData: any = {
      operationCode: operation.code,
      operationDescription: operation.description,
      identifiers: identifiers,
      antennas: antennas,
      username: AppStore.loggedUser?.username ?? '',
      workstation: AppStore.defaultWorkstation ? AppStore.defaultWorkstation.code : '',
      place: AppStore.getWorkstationPlaceCode(),
    }
    if (seconds) mixpanelData.time = seconds + ' seconds'
    if (encodingValidationResponse) {
      mixpanelData.operationToPerform = encodingValidationResponse.operationToPerform || ''
      mixpanelData.errors = encodingValidationResponse.errors || []
    }
    if (force) mixpanelData.force = force
    identifiers.map((idt) => {
      mixpanelData[idt.identifierType] = idt.code
    })
    return mixpanelData
  }

  static getIdentifiers(encodingValidation: EncodingValidationRequest) {
    if (encodingValidation && encodingValidation.identifiers) {
      const identifiers: ConfigurationDetail[] = encodingValidation.identifiers
        .map((idf) => ({
          identifierType: idf.identifierType,
          code: idf.code,
          desiredCode: idf.desiredCode || '',
          role: idf.role,
        }))
        .filter((idf) => !!idf.code)
      return identifiers
    } else {
      return []
    }
  }

  static async create(encodingValidation: EncodingValidationRequest) {
    try {
      const identifiers: ConfigurationDetail[] = encodingValidation.identifiers
        .map((idf) => ({
          identifierType: idf.identifierType,
          code: idf.code,
          tid: idf.tid || '',
          desiredCode: idf.desiredCode || '',
          role: idf.role,
        }))
        .filter((idf) => !!idf.code)
      const res = await Encodings.create({ ...encodingValidation, identifiers })
      return res
    } catch (error) {
      throw new Error(__(T.error.item_creation_error))
    }
  }

  static async force_create(encodingValidation: EncodingValidationRequest, pin?: string) {
    try {
      const identifiers: ConfigurationDetail[] = encodingValidation.identifiers
        .map((idf) => ({
          identifierType: idf.identifierType,
          code: idf.code,
          tid: idf.tid || '',
          desiredCode: idf.desiredCode || '',
          role: idf.role,
        }))
        .filter((idf) => !!idf.code)
      const res = await Encodings.force_create({ ...encodingValidation, identifiers }, pin)
      return res
    } catch (error) {
      throw new Error(__(T.error.item_creation_error))
    }
  }

  static async verify(encodingValidation: EncodingValidationRequest) {
    try {
      const identifiers: ConfigurationDetail[] = encodingValidation.identifiers
        .filter((idf) => idf._read !== false)
        .map((idf) => ({
          identifierType: idf.identifierType,
          code: idf.code,
          tid: idf.tid || '',
        }))
        .filter((idf) => !!idf.code)
      const res = await Encodings.verify({ ...encodingValidation, identifiers })
      encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .forEach((idf) => {
          idf._status = 'CONFIRMED'
          idf._error = undefined
        })

      if (res && res.item && res.item.identifiers) {
        encodingValidation.identifiers
          .filter((idf) => !!idf.code)
          .forEach((idf) => {
            const find = res.item.identifiers.find((idt) => idt.code === idf.code && idt.type === idf.identifierType)
            if (find && !!find.group) {
              idf.role = find.role ?? ''
              const group = res.item.identifiers.find((idt) => idt.group === find.group && idt.type !== find.type)
              if (group) {
                //check if already exist same identifier read
                const exist = encodingValidation.identifiers.filter(
                  (idft) =>
                    idft.identifierType === group.type &&
                    idft.role === group.role &&
                    idft._read &&
                    idft._status !== 'ERROR'
                )
                if (exist.length === 0) {
                  encodingValidation.identifiers.push({
                    identifierType: group.type,
                    role: group.role ?? '',
                    code: group.code ?? '',
                    _read: true,
                    _status: 'CONFIRMED',
                    _error: undefined,
                  })
                }
              }
            }
          })
      }

      res.errors.forEach((error) => {
        const index = encodingValidation.identifiers.findIndex((idf) => idf.code === error.ref)
        if (index >= 0) {
          const errorCode: string = this.getErrorCode(error)

          encodingValidation.identifiers[index]._status = 'ERROR'
          encodingValidation.identifiers[index]._error = errorCode
        } else if (error.errorCode === 'Missing Tag') {
          encodingValidation.identifiers.push({
            code: error.ref,
            ...error.payload,
            _read: false,
            _status: 'ERROR',
            _error: error.errorCode,
          })
        }
      })

      return { encodingValidationResponse: res, encodingValidation }
    } catch (error) {
      throw new Error((error as any).message ?? __(T.error.item_creation_error))
    }
  }

  static getErrorCode = (error) => {
    let errorCode: string = error.errorCode
    if (errorCode === 'Tag Mismatch') errorCode = __(T.error.tags_mismatch_error)
    return errorCode
  }

  static async onTagRead(
    encodingValidation: EncodingValidationRequest,
    _tag: TmrTag,
    operationConfig: EncodingOperationConfig
  ) {
    let identifierType = 'UHF_TAG'
    if (!!_tag.uid) identifierType = 'NFC_TAG'
    if (!!_tag.barcode) identifierType = 'SIMPLE_ITEM_IDENTIFIER'

    const tag = {
      code: _tag.epc ?? _tag.uid ?? _tag.barcode,
      tid: _tag.tid || '',
      identifierType,
    } as { code: string; tid?: string; identifierType: IdentifierType }
    //TAG UID aggiungi NFC
    const index = encodingValidation.identifiers.findIndex(
      (identifier) =>
        (!identifier.code && identifier.identifierType === tag.identifierType) ||
        (identifier.code === tag.code && identifier.identifierType === tag.identifierType)
    )

    if (index >= 0) {
      encodingValidation.identifiers[index].code = tag.code
      encodingValidation.identifiers[index].tid = tag.tid || ''
      encodingValidation.identifiers[index]._status = 'PROCESSING'
      encodingValidation.identifiers[index]._error = undefined
      encodingValidation.identifiers[index]._read = true
    } else {
      encodingValidation.identifiers.push({
        code: tag.code,
        tid: tag.tid || '',
        identifierType: tag.identifierType,
        _status: 'PROCESSING',
      })
    }
  }

  static resetIdentifiersEncodingValition(
    operation: EncodingOperationConfig,
    encodingValidationResponse?: EncodingValidationResponse,
    encodingValidation?: EncodingValidationRequest
  ) {
    const expectOnlyMandatoryIdentifiers = this.isExpectOnlyMandatoryIdentifiers(operation)
    const identifiers: ConfigurationDetail[] = []
    if (encodingValidationResponse && encodingValidationResponse.configuration) {
      let configurationDetails
      if (encodingValidationResponse.configurationDetails)
        configurationDetails = encodingValidationResponse.configurationDetails
      else if (encodingValidationResponse.configuration && encodingValidationResponse.configuration.details)
        configurationDetails = encodingValidationResponse.configuration.details
      if (!configurationDetails) configurationDetails = []

      configurationDetails.forEach((configDetail) => {
        if (!expectOnlyMandatoryIdentifiers || (expectOnlyMandatoryIdentifiers && !configDetail.optional)) {
          identifiers.push({ ...configDetail })
        }
      })
    }
    return identifiers
  }

  static getOptionEncodingPage(value, encodingConfig: EncodingOperationConfig) {
    switch (value) {
      case 'associate':
        Router.navigate(`/encoding/:opCode`, { opCode: encodingConfig.code })
        break
      case 'verify':
        Router.navigate(`/encoding/:opCode/verify`, { opCode: encodingConfig.code })
        break
      default:
        Router.navigate(`/encoding/:opCode`, { opCode: encodingConfig.code })
        break
    }
  }

  static sortIdentifiers(identifiers: ConfigurationDetail[]) {
    //Sort identifiers with first the identiferType as SIMPLEITEMIDENTIFIER and then the others
    return identifiers.sort((a, b) => {
      if (a.identifierType === 'SIMPLE_ITEM_IDENTIFIER' && a._status !== 'CONFIRMED') {
        return -1
      } else if (b.identifierType === 'SIMPLE_ITEM_IDENTIFIER' && b._status !== 'CONFIRMED') {
        return 1
      }
      return 0
    })
  }
}
